import {} from '../vendor/swiper.js';

const mainNewsSlider = document.querySelector('.main-news__slider');

const initSlider = (element) => {
  // eslint-disable-next-line no-undef
  const slider = new Swiper(element, {
    a11y: {
      notificationClass: 'main-news__notification',
      nextSlideMessage: 'Следующий слайд',
      prevSlideMessage: 'Предыдущий слайд',
    },
    loop: true,
    autoHeight: true,
    calculateHeight: true,
    containerModifierClass: 'main-news--',
    slideClass: 'main-news__slide',
    slideActiveClass: 'main-news__slide--active',
    slideNextClass: 'main-news__slide--next',
    slidePrevClass: 'main-news__slide--prev',
    slideDuplicateActiveClass: 'main-news__slide--duplicate-active',
    slideDuplicateClass: 'main-news__slide--duplicate',
    slideDuplicatePrevClass: 'main-news__slide--duplicate-prev',
    slideDuplicateNextClass: 'main-news__slide--duplicate-next',
    wrapperClass: 'main-news__slider-wrapper',
    grabCursor: true,
    navigation: {
      nextEl: '.main-news__slider-button--next',
      prevEl: '.main-news__slider-button--prev',
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
    },
  });

  return slider;
};

const initMainNewsSlider = () => {
  if (!mainNewsSlider) {
    return;
  } else {
    initSlider(mainNewsSlider);
  }
};

export {
  initMainNewsSlider
};
