import {closeAllOpenAccordion} from '../modules/menu';

export const closeHeaderAccordion = () => {
  const accordions = document.querySelectorAll('.main-nav__item');
  if (!accordions.length) {
    return;
  }

  accordions.forEach((item) => {
    item.addEventListener('click', () => {
      if (document.documentElement.clientWidth > 1023) {
        document.addEventListener('click', (evt) => {
          const target = evt.target;
          if (target.closest('[data-accordion]') && !target.classList.contains('main-nav__item')) {
            return;
          }
          closeAllOpenAccordion();
        });

        document.addEventListener('keydown', (evt) => {
          const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

          if (isEscKey) {
            evt.preventDefault();
            closeAllOpenAccordion();
          }
        });
      }
    });
  });
};
