const videos = document.querySelectorAll('.video');

const initVideo = () => {
  if (!videos) {
    return;
  }

  document.addEventListener('click', (evt) => {
    const video = evt.target.closest('.video');

    if (video) {
      const player = video.querySelector('iframe');

      // eslint-disable-next-line no-inner-declarations
      function playVideo() {
        player.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
      }
      playVideo();
    }
  });
};

export {initVideo};
