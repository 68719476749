const videoItems = document.querySelectorAll('.video__links-item');
const videoWrap = document.querySelector('.video');


const initSelectVideo = () => {
  if (!videoItems.length || !videoWrap) {
    return;
  }

  const video = videoWrap.querySelector('iframe');

  videoItems.forEach((item) => {
    item.addEventListener('click', (evt) => {
      evt.preventDefault();
      videoItems.forEach((el) => el.classList.remove('video__links-item--active'));
      item.classList.add('video__links-item--active');

      const activeLink = item.querySelector('.video__link');
      const href = activeLink.getAttribute('href');
      const src = `https://www.youtube.com/embed/${href}?enablejsapi=1?&autoplay=1`;

      video.setAttribute('src', src);
    });
  });
};

export {initSelectVideo};
