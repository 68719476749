const buttonMenu = document.querySelector('.main-nav__toggle');
const menu = document.querySelector('.main-nav__wrapper');

export const closeAllOpenAccordion = () => {
  const accordions = menu.querySelectorAll('.main-nav__item.is-active');

  if (!accordions) {
    return;
  }

  accordions.forEach((element) => {
    const contentElement = element.querySelector('[data-accordion="content"]');
    element.classList.remove('is-active');
    contentElement.style.maxHeight = '0';
  });
};


export const initMenu = () => {
  if (!buttonMenu) {
    return;
  }
  const textOpen = buttonMenu.getAttribute('data-text-open');
  const textClose = buttonMenu.getAttribute('data-text-close');

  const openMenu = () => {
    menu.classList.add('main-nav__wrapper--active');
    buttonMenu.classList.add('main-nav__toggle--open');
    buttonMenu.setAttribute('aria-label', textClose);
    buttonMenu.setAttribute('aria-pressed', true);

    menu.addEventListener('click', (evt) => {
      const target = evt.target;
      if (target.closest('[data-accordion]')) {
        return;
      }
      closeMenu();
    });
  };

  const closeMenu = () => {
    menu.classList.remove('main-nav__wrapper--active');
    buttonMenu.classList.remove('main-nav__toggle--open');
    buttonMenu.setAttribute('aria-label', textOpen);
    buttonMenu.setAttribute('aria-pressed', false);
  };

  buttonMenu.addEventListener('click', () => {
    if (!menu.classList.contains('main-nav__wrapper--active')) {
      openMenu();
      window.scrollLock.disableScrolling();

      document.addEventListener('keydown', (evt) => {
        const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

        if (isEscKey && menu.classList.contains('main-nav__wrapper--active')) {
          evt.preventDefault();
          closeMenu();
          window.scrollLock.enableScrolling();
        }
      });

    } else {
      closeAllOpenAccordion();
      closeMenu();
      window.scrollLock.enableScrolling();
    }


    window.addEventListener('resize', () => {
      if (document.documentElement.clientWidth > 1023) {
        closeMenu();
        window.scrollLock.enableScrolling();
      }
    });
  });

  window.addEventListener('resize', () => {
    closeAllOpenAccordion();
  });
};
