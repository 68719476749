const inputs = document.querySelectorAll('.custom-input input');


const initHidePlaceholder = () => {
  if (!inputs.length) {
    return;
  }

  const placeholders = document.querySelectorAll('.custom-input__label');

  inputs.forEach((input, i) => {

    input.onfocus = () => {
      placeholders[i].style.opacity = 0;
    };

    input.onblur = () => {
      if (input.value) {
        placeholders[i].style.opacity = 0;
      } else {
        placeholders[i].style.opacity = 1;
      }
    };
  });
};

export {initHidePlaceholder};
